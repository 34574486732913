/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const BUTTONS_CONTENT_TYPE = 'buttons';

export const BUTTONS_SKELETON = [{
    name: 'BaseButtons',
    type: 'div'
}];
