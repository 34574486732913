/**
 * @category  Scandiweb
 * @package   ScandiPWA/Moller
 * @author    Peter Atef <info@scandiweb.com>
 */

import {
    Meta as SourceMeta
} from 'SourceComponent/Meta/Meta.component';

/** @namespace Scandipwa/Component/Meta/Component */
export class MetaComponent extends SourceMeta {
    /**
     * Overridden to only render the dynamic title as oppose to
     * rendering the default title first then the the dynamic
     */
    renderTitle() {
        const {
            title_prefix,
            title_suffix,
            title
        } = this.props;

        const titlePrefix = title_prefix ? `${ title_prefix } | ` : '';
        const titleSuffix = title_suffix ? ` | ${ title_suffix }` : '';
        const { value = title } = title;

        if (!value) {
            return null;
        }

        return (
            <title>
                { `${ titlePrefix }${ value }${ titleSuffix }` }
            </title>
        );
    }
}

export default MetaComponent;
